import Footer from "./components/Footer";
import Intro from "./pages/Intro";
import About from "./pages/About";
import Akademik from "./pages/Akademik";
import Penelitian from "./pages/Penelitian";
import Pengabdian from "./pages/Pengabdian";
import Alumni from "./pages/Alumni";
import Error from "./pages/Error";
import Berita from "./pages/about/Berita";
import StrukturOrganisasi from "./pages/about/StrukturOrganisasi";
import Pimpinan from "./pages/about/Pimpinan";
import Senat from "./pages/about/Senat";
import LogoKampus from "./pages/about/LogoKampus";
import LegalitasKampus from "./pages/about/LegalitasKampus";
import SejarahSingkat from "./pages/about/SejarahSingkat";
import AgendaAkademik from "./pages/akademik/AgendaAkademik";
import NavigationBar from "./components/NavigationBar";
import "./style/theme.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import BeritaDetail from "pages/about/BeritaDetail";
import PengabdianDetail from "pages/about/PengabdianDetail";
import InfoJalurMasuk from "pages/pmb/InfoJalurMasuk";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavigationBar />
        <Routes>
          <Route path="/" element={<Intro />}></Route>
          <Route path="/tentang-itba-algazali" element={<About />}></Route>
          <Route path="/berita" element={<Berita />}></Route>
          <Route path="/berita/:id/:slug" element={<BeritaDetail />}></Route>
          <Route path="/sejarah-singkat" element={<SejarahSingkat />}></Route>
          <Route
            path="/struktur-organisasi"
            element={<StrukturOrganisasi />}
          ></Route>
          <Route
            path="/pimpinan-perguruan-tinggi"
            element={<Pimpinan />}
          ></Route>
          <Route path="/senat-akademik" element={<Senat />}></Route>
          <Route path="/logo-kampus" element={<LogoKampus />}></Route>
          <Route path="/legalitas-kampus" element={<LegalitasKampus />}></Route>
          <Route path="/akademik" element={<Akademik />}></Route>
          <Route path="/agenda-akademik" element={<AgendaAkademik />}></Route>
          <Route path="/penelitian" element={<Penelitian />}></Route>
          <Route path="/pengabdian" element={<Pengabdian />}></Route>
          <Route
            path="/pengabdian/:id/:slug"
            element={<PengabdianDetail />}
          ></Route>
          <Route path="/alumni" element={<Alumni />}></Route>
          <Route path="/info-jalur-masuk" element={<InfoJalurMasuk />}></Route>
          <Route path="*" element={<Error />}></Route>
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
