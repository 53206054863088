import { Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import { dateFormat } from "assets/js/dateFormat";
import axios from "axios";
import "./Berita.css";
import { API_URL } from "config";

const Berita = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/berita`)
      .then((response) => {
        const sortedData = response.data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setData(sortedData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleButton = (id, slug) => {
    window.location.href = `/berita/${id}/${slug}`;
  };

  return (
    <div>
      <Container className="full-container head-container">
        <div className="head-box">
          <div className="head-title">Berita Terkini ITBA Al Gazali</div>
          <div className="head-subtitle"></div>
        </div>
      </Container>
      <Container className="full-container sejarah-singkat-container">
        <div className="item-box card-normal__style">
          <div className="item-box__content">
            <div className="item-box__title"></div>
            <div className="item-box__subtitle"></div>
            <div className="news-item__container">
              {data.map((obj, index) => (
                <div
                  className="news-item"
                  key={index}
                  onClick={() => handleButton(obj.id, obj.news_slug)}
                >
                  <div className="news-thumb">
                    <img
                      src={`${API_URL}/news/${obj.news_thumb}`}
                      alt="Thumb"
                    />
                  </div>
                  <div className="news-title">{obj.news_title}</div>
                  <div className="news-date">{dateFormat(obj.createdAt)}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Berita;
