import { Container, Table } from "react-bootstrap";
import "./InfoJalurMasuk.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "config";
import registPic from "../../assets/img/registartion.jpg";
import bannerPic from "../../assets/img/pmb.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

const Alumni = () => {
  const [prodi, setProdi] = useState([]);
  const biayaRegistrasi = [
    { name: "Uang Pendaftaran", nominal: "Rp. 930.000" },
    { name: "Uang Pembangunan", nominal: "Rp. 1.700.000" },
    { name: "M-Opiq", nominal: "Rp. 340.000" },
    { name: "Almamater", nominal: "Rp. 230.000" },
    { name: "Kartu Mahasiswa/Perpustakaan", nominal: "Rp. 50.000" },
    { name: "Iuran Senat", nominal: "Rp. 325.000" },
  ];
  const biayaSpp = [
    { name: "Administrasi Publik", nominal: "Rp. 900.000" },
    { name: "Sistem Informasi", nominal: "Rp. 3.000.000" },
    { name: "Bisnis Digital", nominal: "Rp. 2.500.000" },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      axios
        .get(`${API_URL}/prodi`)
        .then((response) => {
          setProdi(response.data);
        })
        .catch((errors) => {
          console.error(errors);
        });
    };

    fetchData();
  }, []);
  return (
    <div>
      <Container className="full-container head-container">
        <div className="head-box pmb-container">
          <div className="head-title">Pendaftaran Mahasiswa Baru</div>
          <div className="head-subtitle">
            Informasi Penerimaan Mahasiswa Baru ITBA Al Gazali Barru
          </div>
        </div>
      </Container>
      <Container className="full-container penelitian-container">
        <div className="item-box card-normal__style">
          <div>
            <img
              src={bannerPic}
              alt="Logo"
              data-aos="fade-up"
              className="item-box__banner"
            />
          </div>
          <div className="item-box__content">
            <h3 className="text-info mb-0 mt-4" data-aos="fade-right">
              DAFTAR
            </h3>
            <h3 className="mb-4" data-aos="fade-left">
              PROGRAM STUDI
            </h3>
            <div className="item-box__cards">
              {prodi.map((obj, index) => (
                <div key={index} data-aos="fade-up">
                  <div className="item-box__card">
                    <h4>
                      {obj.prodi_name} - {obj.jenjang_prodi}
                    </h4>
                    <span className="text-center align-middle bg-info w-50 mx-auto rounded py-1 text-light">
                      {obj.peringkat ?? "PROGRAM STUDI BARU"}
                    </span>
                    <span className="text-center align-middle fw-light">
                      {obj.info_prodi}
                    </span>
                    <div className="button-container v2">
                      <span>Selengkapnya</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <h3 className="text-info mt-5 mb-0" data-aos="fade-right">
              BIAYA KULIAH
            </h3>
            <h3 className="mb-4" data-aos="fade-left">
              REGISTRASI ULANG
            </h3>
            <h5 className="text-start mt-4" data-aos="fade-up">
              Biaya Registrasi Ulang
            </h5>
            <Table responsive="sm" data-aos="fade-left">
              <thead className="bg-info text-light">
                <tr>
                  <th className="text-center align-middle">NO</th>
                  <th className="text-center align-middle">Nama</th>
                  <th className="text-center align-middle">Biaya</th>
                </tr>
              </thead>
              <tbody>
                {biayaRegistrasi.map((obj, index) => (
                  <tr key={index}>
                    <td className="text-center align-middle">{index + 1}</td>
                    <td className="text-center align-middle">{obj.name}</td>
                    <td className="text-center align-middle">{obj.nominal}</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <h5 className="text-start mt-5" data-aos="fade-up">
              Biaya SPP
            </h5>
            <Table responsive="sm" data-aos="fade-right">
              <thead className="bg-info text-light">
                <tr>
                  <th className="text-center align-middle">NO</th>
                  <th className="text-center align-middle">Nama</th>
                  <th className="text-center align-middle">Biaya</th>
                </tr>
              </thead>
              <tbody>
                {biayaSpp.map((obj, index) => (
                  <tr key={index}>
                    <td className="text-center align-middle">{index + 1}</td>
                    <td className="text-center align-middle">{obj.name}</td>
                    <td className="text-center align-middle">{obj.nominal}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="item-box__regist">
            <div>
              <h3 className="text-info mt-5 mb-0" data-aos="fade-right">
                PROSEDUR PENDAFTARAN
              </h3>
              <h3 className="mb-4" data-aos="fade-left">
                MAHASISWA BARU
              </h3>
              <li data-aos="fade-up">
                Calon mahasiswa baru mengisi{" "}
                <a
                  className="text-primary text-decoration-underline"
                  href="https://bit.ly/pmb_institut_algazalibarru"
                >
                  formulir pendaftaran
                </a>{" "}
                secara online.
              </li>
              <li data-aos="fade-up">
                Calon mahasiswa baru melakukan pembayaran biaya pendaftaran
                sebesar <span className="fw-semibold"> Rp. 300.000</span>
              </li>
              <li data-aos="fade-up">
                Calon mahasiswa dapat mencetak kartu ujian, yang dapat diperoleh
                secara online dari Panitia PMB.
              </li>
              <li data-aos="fade-up">
                Calon mahasiswa mengikuti ujian masuk secara online atau secara
                langsung di kampus 1 ( Satu ) Institut Al Gazali Barru
              </li>
              <li data-aos="fade-up">
                Bagi calon mahasiswa yang lulus ujian masuk, diwajibkan untuk
                melakukan pendaftaran ulang untuk mendapatkan Nomor Induk
                Mahasiswa (NIM) dan dinyatakan sebagai mahasiswa baru pada tahun
                akademik 2024/2025
              </li>
              <li data-aos="fade-up">
                Untuk informasi lebih lanjut, silakan hubungi nomor
                telepon/WhatsApp di{" "}
                <span className="fw-semibold">
                  {" "}
                  082331500209,08124115572 & 082288727001
                </span>
              </li>
            </div>
            <img src={registPic} alt="Logo" data-aos="fade-up" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Alumni;
