import { useEffect, useState } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import logo from "../assets/img/itba.png";
import DropdownBox from "./DropdownBox";

// ES6 Style
const NavigationBar = () => {
  const [isActive, setIsActive] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleClick = () => {
    setIsActive((current) => !current);
    const html = document.querySelector("html");
    isActive
      ? (html.style.overflow = "auto")
      : (html.style.overflow = "hidden");
  };

  useEffect(() => {
    function updatePosition() {
      setScrollPosition(window.pageYOffset);
    }

    window.addEventListener("scroll", updatePosition);
    updatePosition();

    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  return (
    <div>
      <Navbar className={scrollPosition > 100 ? "fixed-top move" : "fixed-top"}>
        <Container className="container navbar__container">
          <Navbar.Brand>
            <Nav.Link href="/">
              <img src={logo} width="80" alt="ITBA Al Gazali Barru" />
            </Nav.Link>
          </Navbar.Brand>
          <Nav>
            <Nav.Link href="/tentang-itba-algazali" className="nav-item">
              <span className="nav-item__title">Tentang ITBA Al Gazali</span>
            </Nav.Link>
            <Nav.Link href="/akademik" className="nav-item">
              <span>Akademik</span>
            </Nav.Link>
            <Nav.Link href="/penelitian" className="nav-item">
              <span>Penelitian</span>
            </Nav.Link>
            <Nav.Link href="/pengabdian" className="nav-item">
              <span>Pengabdian</span>
            </Nav.Link>
            <Nav.Link href="alumni" className="nav-item">
              <span>Alumni</span>
            </Nav.Link>
            <div>
              <li
                className={isActive ? "bars open" : "bars"}
                onClick={handleClick}
              >
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
              </li>
            </div>
          </Nav>
        </Container>
      </Navbar>
      <DropdownBox status={isActive} />
    </div>
  );
};

export default NavigationBar;
