import { Container } from "react-bootstrap";
import "./Error.css";

const Error = () => {
  return (
    <div>
      <Container className="full-container error-container">
        <div className="error-box">
          <div className="error-title"></div>
          <div className="error-subtitle"></div>
        </div>
      </Container>
    </div>
  );
};

export default Error;
