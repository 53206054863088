import axios from "axios";
import { API_URL } from "config";

const repositoryData = (path, slug) => {
  const getRepository = async () => {
    try {
      const response = await axios.get(`${API_URL}/${path}`);
      if (response.data) {
        const dataFile = response.data[`${slug}`];
        const getUrl = `${API_URL}/repository/${dataFile}`;
        const link = document.createElement("a");
        link.href = getUrl;
        link.setAttribute("download", response.data.item_file);
        document.body.appendChild(link);
        link.click();
      }
    } catch (err) {
      console.log(err.message);
    } finally {
    }
  };
  getRepository();
};

export default repositoryData;
