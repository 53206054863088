import { Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import "./LegalitasKampus.css";
import repositoryData from "../../assets/js/DownloadRepository";
import { useEffect, useState } from "react";
import { API_URL } from "config";
import axios from "axios";
import { dateFormatGetYear } from "assets/js/dateFormat";

const LegalitasKampus = () => {
  const [kampus, setKampus] = useState([]);
  const [prodi, setProdi] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const requestkampus = axios.get(`${API_URL}/about/legalitas_kampus`);
      const requestProdi = axios.get(`${API_URL}/prodi`);

      axios
        .all([requestkampus, requestProdi])
        .then(
          axios.spread((...responses) => {
            const responsekampus = responses[0];
            const responseProdi = responses[1];

            setKampus(responsekampus.data);
            setProdi(responseProdi.data);
          })
        )
        .catch((errors) => {
          console.error(errors);
        });
    };

    fetchData();
  }, []);

  return (
    <div>
      <Container className="full-container head-container">
        <div className="head-box">
          <div className="head-title">Legalitas Kampus</div>
          <div className="head-subtitle"></div>
        </div>
      </Container>
      <Container className="full-container legalitas-kampus-container">
        <div className="item-box card-normal__style">
          <div className="item-box__content">
            <div className="item-box__title">
              <span>Dokemen Legalitas Kampus ITBA Al Gazali Barru</span>
            </div>
            <div className="item-box__subtitle">
              <h5>
                Legalitas Kampus{" "}
                <span
                  button-data="akreditasi_kampus"
                  onClick={() =>
                    repositoryData(`about/${kampus.id_item}`, "item_file")
                  }
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  {" "}
                  Download
                </span>
              </h5>
            </div>
            <div className="item-box__subtitle">
              <h5>Legalitas Program Studi</h5>
              <Table responsive="sm">
                <thead>
                  <tr>
                    <th className="text-center align-middle">NO</th>
                    <th className="text-center align-middle">PROGRAM STUDI</th>
                    <th className="text-center align-middle">PROGRAM</th>
                    <th className="text-center align-middle">
                      NOMOR SK. AKREDITASI
                    </th>
                    <th className="text-center align-middle">TAHUN</th>
                    <th className="text-center align-middle">
                      DALUWARSA AKREDITASI
                    </th>
                    <th className="text-center align-middle">PERINGKAT</th>
                    <th className="text-center align-middle">DOWNLOAD</th>
                  </tr>
                </thead>
                <tbody>
                  {prodi.map((obj, index) => (
                    <tr key={index}>
                      <td className="text-center align-middle">{index + 1}</td>
                      <td className="text-center align-middle">
                        {obj.prodi_name}
                      </td>
                      <td className="text-center align-middle">
                        {obj.jenjang_prodi}
                      </td>
                      <td className="text-center align-middle">
                        {obj.no_sk_akredit}
                      </td>
                      <td className="text-center align-middle">
                        {dateFormatGetYear(obj.tahun_akredit)}
                      </td>
                      <td className="text-center align-middle">
                        {obj.expired_akredit}
                      </td>
                      <td className="text-center align-middle">
                        {obj.peringkat}
                      </td>
                      <td className="text-center align-middle">
                        <span
                          button-data={obj.prodi_id}
                          onClick={() =>
                            repositoryData(
                              `prodi/${obj.id}/${obj.prodi_id}`,
                              "file_akreditasi"
                            )
                          }
                          style={{ color: "blue", cursor: "pointer" }}
                        >
                          Download
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default LegalitasKampus;
