import { Container } from "react-bootstrap";
import "./SejarahSingkat.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import "react-quill/dist/quill.snow.css";
import { API_URL } from "config";

const SejarahSingkat = () => {
  const [sejarah, setSejarah] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/about/sejarah_kampus`)
      .then((response) => {
        setSejarah(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <Container className="full-container head-container">
        <div className="head-box">
          <div className="head-title">Sejarah Singkat ITBA Al Gazali</div>
          <div className="head-subtitle"></div>
        </div>
      </Container>
      <Container className="full-container sejarah-singkat-container">
        <div className="item-box card-normal__style">
          <div className="item-box__content">
            <div className="item-box__title">
              <span>Sejarah</span>
            </div>
            <div
              className="ql-editor"
              dangerouslySetInnerHTML={{
                __html: sejarah.item_content,
              }}
            ></div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SejarahSingkat;
