import { Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";
import "react-quill/dist/quill.snow.css";
import { dateFormat } from "assets/js/dateFormat";
import { API_URL } from "config";
import "./Pengabdian.css";

const Pengabdian = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/pengabdian`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleButton = (id, slug) => {
    window.location.href = `/pengabdian/${id}/${slug}`;
  };

  return (
    <div>
      <Container className="full-container head-container">
        <div className="head-box">
          <div className="head-title">Pengabdian ITBA Al Gazali Barru</div>
          <div className="head-subtitle"></div>
        </div>
      </Container>
      <Container className="full-container penelitian-container">
        <div className="item-box card-normal__style">
          <div className="item-box__content">
            <div className="item-box__title"></div>
            <div className="item-box__subtitle"></div>
            <div className="news-item__container">
              {data.map((obj, index) => (
                <div
                  className="news-item"
                  key={index}
                  onClick={() => handleButton(obj.id, obj.pengabdian_slug)}
                >
                  <div className="news-thumb">
                    <img
                      src={`${API_URL}/pengabdian/${obj.pengabdian_thumb}`}
                      alt="Thumb"
                    />
                  </div>
                  <div className="news-title">{obj.pengabdian_title}</div>
                  <div className="news-date">{dateFormat(obj.createdAt)}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Pengabdian;
