import { Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import { dateFormat } from "assets/js/dateFormat";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./BeritaDetail.css";
import "react-quill/dist/quill.snow.css";
import { API_URL } from "config";

const BeritaDetail = () => {
  const { id, slug } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/berita/${id}/${slug}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, slug]);

  return (
    <div>
      <Container className="full-container head-container">
        <div className="head-box">
          <div className="head-title">{data.news_title}</div>
          <div className="head-subtitle"></div>
        </div>
      </Container>
      <Container className="full-container sejarah-singkat-container">
        <div className="item-box card-normal__style">
          <div className="item-box__content">
            <div className="item-box__title"></div>
            <div className="item-box__subtitle"></div>
            <div className="news-detail-item__container">
              <div className="news-detail-thumb">
                <img src={`${API_URL}/news/${data.news_thumb}`} alt="Thumb" />
              </div>
              <div className="news-detail-date">
                {dateFormat(data.createdAt)}
              </div>
              <div className="news-detail-author">
                By <span>{data.news_author}</span>
              </div>
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{
                  __html: data.news_content,
                }}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default BeritaDetail;
