import "./CalenderEvent.css";
import Calender from "../assets/js/Calender";
import { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const CalenderEvent = () => {
  useEffect(() => {
    Calender();
  }, []);

  return (
    <div className="calendar-event-container">
      <div className="calender-box">
        <header>
          <p className="current-date "></p>
          <div className="icons">
            <FaChevronLeft id="prev" />
            <FaChevronRight id="next" />
          </div>
        </header>
        <div className="calender">
          <ul className="weeks">
            <li>Min</li>
            <li>Sen</li>
            <li>Sel</li>
            <li>Rab</li>
            <li>Kam</li>
            <li>Jum</li>
            <li>Sab</li>
          </ul>
          <ul className="days"></ul>
        </div>
      </div>
      <div className="event-box"></div>
    </div>
  );
};

export default CalenderEvent;
