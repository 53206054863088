import { Container } from "react-bootstrap";
import CalenderEvent from "../../components/CalenderEvent";
import "./AgendaAkademik.css";

const AgendaAkademik = () => {
  return (
    <div>
      <Container className="full-container head-container">
        <div className="head-box">
          <div className="head-title">Agenda Akademik ITBA Al Gazali</div>
          <div className="head-subtitle"></div>
        </div>
      </Container>
      <Container className="full-container agenda-akademik-container">
        <div className="item-box card-normal__style">
          <div className="item-box__content">
            <CalenderEvent />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AgendaAkademik;
