import { Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";
import "react-quill/dist/quill.snow.css";
import { API_URL } from "config";
import "./Penelitian.css";

const Penelitian = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/publikasi`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <Container className="full-container head-container">
        <div className="head-box">
          <div className="head-title">
            Jurnal & Penelitian ITBA Al Gazali Barru
          </div>
          <div className="head-subtitle"></div>
        </div>
      </Container>
      <Container className="full-container penelitian-container">
        <div className="item-box card-normal__style">
          <div className="item-box__content">
            <div className="item-box__title"></div>
            <div className="item-box__subtitle"></div>
            <div className="jurnal-item__container">
              {data.map((obj, index) => (
                <a
                  target={"_blank"}
                  href={obj.jurnal_url}
                  key={index}
                  rel="noreferrer"
                >
                  <div className="jurnal-item">
                    <div className="jurnal-thumb">
                      <img
                        src={`${API_URL}/jurnal/${obj.jurnal_thumb}`}
                        alt="Thumb"
                      />
                    </div>
                    <div className="jurnal-title">{obj.jurnal_name}</div>
                    <div className="jurnal-desc">{obj.jurnal_desc}</div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Penelitian;
