import { Nav } from "react-bootstrap";

const DropdownBox = (value) => {
  return (
    <div>
      <div
        className={
          value.status ? "navbar-dropdown open" : "navbar-dropdown close"
        }
      >
        <div className="navbar_dropdown_container">
          <div className="dorpdown-item_box">
            <Nav.Link href="/tentang-itba-algazali">
              <span className="dorpdown-item__title header">
                Tentang ITBA Al Gazali
              </span>
            </Nav.Link>
            <Nav.Link href="/berita">
              <span className="dorpdown-item__title">Berita</span>
            </Nav.Link>
            <Nav.Link href="/sejarah-singkat">
              <span className="dorpdown-item__title">Sejarah Singkat</span>
            </Nav.Link>
            <Nav.Link href="/tentang-stia-algazali">
              <span className="dorpdown-item__title">Visi & Misi</span>
            </Nav.Link>
            <Nav.Link href="struktur-organisasi">
              <span className="dorpdown-item__title">Struktur Organisasi</span>
            </Nav.Link>
            <Nav.Link href="/pimpinan-perguruan-tinggi">
              <span className="dorpdown-item__title">
                Pimpinan Perguruan Tinggi
              </span>
            </Nav.Link>
            <Nav.Link href="/senat-akademik" className="senat-akademik">
              <span className="dorpdown-item__title">Senat Akademik</span>
            </Nav.Link>
            <Nav.Link href="/tentang-stia-algazali">
              <span className="dorpdown-item__title">
                Statuta Perguruan Tinggi
              </span>
            </Nav.Link>
            <Nav.Link href="/logo-kampus">
              <span className="dorpdown-item__title">Logo Resmi</span>
            </Nav.Link>
            <Nav.Link href="legalitas-kampus">
              <span className="dorpdown-item__title">Legalitas Kampus</span>
            </Nav.Link>
          </div>
          <div className="dorpdown-item_box">
            <Nav.Link href="/akademik">
              <span className="dorpdown-item__title header">Akademik</span>
            </Nav.Link>
            <Nav.Link href="/akademik">
              <span className="dorpdown-item__title">Program Studi</span>
            </Nav.Link>
            <Nav.Link target="_blank" href="https://siakad.algazali.ac.id">
              <span className="dorpdown-item__title">SIAKAD</span>
            </Nav.Link>
            <Nav.Link target="_blank" href="https://lib.algazali.ac.id">
              <span className="dorpdown-item__title">Perpustakaan Digital</span>
            </Nav.Link>
            <Nav.Link href="/agenda-akademik">
              <span className="dorpdown-item__title">Agenda Akademik</span>
            </Nav.Link>
            <Nav.Link target="_blank" href="https://sister.kemdikbud.go.id">
              <span className="dorpdown-item__title">SISTER</span>
            </Nav.Link>
            <Nav.Link href="/info-beasiswa">
              <span className="dorpdown-item__title">Beasiswa</span>
            </Nav.Link>
          </div>
          <div className="dorpdown-item_box">
            <Nav.Link href="/penelitian">
              <span className="dorpdown-item__title header">Penelitian</span>
            </Nav.Link>
            <Nav.Link href="/penelitian">
              <span className="dorpdown-item__title">Jurnal Publikasi</span>
            </Nav.Link>
          </div>
          <div className="dorpdown-item_box">
            <Nav.Link href="/pengabdian">
              <span className="dorpdown-item__title header">Pengabdian</span>
            </Nav.Link>
            <Nav.Link href="/pengabdian">
              <span className="dorpdown-item__title">Program Pengabdian</span>
            </Nav.Link>
          </div>
          <div className="dorpdown-item_box">
            <Nav.Link href="/alumni">
              <span className="dorpdown-item__title header">Alumni</span>
            </Nav.Link>
            <Nav.Link href="/alumni">
              <span className="dorpdown-item__title">Alumni Kampus</span>
            </Nav.Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropdownBox;
