import logo from "../assets/img/itba.png";
import { FaInstagram, FaWhatsapp, FaYoutube, FaFacebook } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-box">
        <div className="footer-box__container">
          <div className="footer-item logo">
            <img src={logo} alt="Logo" />
          </div>
          <div className="footer-item border-item">
            <div className="footer-item__title">
              Kampus ITBA Al Gazali Barru
            </div>
            Jl. Jend Sudirman No. 41 Barru - Sulawesi Selatan
            <br></br>
            Tlpn : (0427) 21871
            <br></br>
            Email : info@algazali.ac.id
          </div>
          <div className="footer-item">
            <div className="footer-item__title">Media Sosial</div>
            <div className="sosmed-container">
              <a
                target="_blank"
                href="https://web.facebook.com/stia.barru"
                rel="noreferrer"
              >
                <h3>
                  <FaFacebook />
                </h3>
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/stiaalgazalibarru_/"
                rel="noreferrer"
              >
                <h3>
                  <FaInstagram />
                </h3>
              </a>
              <a
                target="_blank"
                href="https://api.whatsapp.com/send/?phone=6287751294295&text&type=phone_number&app_absent=0"
                rel="noreferrer"
              >
                <h3>
                  <FaWhatsapp />
                </h3>
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/@stiaalgazalibarru8859"
                rel="noreferrer"
              >
                <h3>
                  <FaYoutube />
                </h3>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-cipta">2024 Hak Cipta, ITB Al Gazali Barru</div>
    </div>
  );
};

export default Footer;
