import axios from "axios";
import { API_URL } from "config";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "./Pimpinan.css";
import "react-quill/dist/quill.snow.css";

const Pimpinan = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/about/pimpinan_perguruan_tinggi`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <Container className="full-container head-container">
        <div className="head-box">
          <div className="head-title">Pimpinan ITBA Al Gazali Barru</div>
          <div className="head-subtitle"></div>
        </div>
      </Container>
      <Container className="full-container pimpinan-container">
        <div className="item-box card-normal__style">
          <div className="item-box__content">
            <div className="item-box__title">
              <span>Pimpinan Perguruan Tinggi</span>
            </div>
            <div className="item-box__thumb pimpinan-struktur_organisasi_thumb">
              <img
                id="pimpinan-struktur_organisasi"
                src={process.env.PUBLIC_URL + "uploads/img/pimpinan.png"}
                alt="Thumb"
              />
            </div>
            <div
              className="ql-editor"
              dangerouslySetInnerHTML={{
                __html: data.item_content,
              }}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Pimpinan;
