import { Container } from "react-bootstrap";
import repositoryData from "../../assets/js/DownloadRepository";
import { useEffect, useState } from "react";
import "./LogoKampus.css";
import axios from "axios";
import { API_URL } from "config";

const LogoKampus = () => {
  const [logoWarna, setLogoWarna] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/about/all/logo`)
      .then((response) => {
        setLogoWarna(
          response.data.find((obj) => obj.id_item === "logo_berwarna_kampus")
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <Container className="full-container head-container">
        <div className="head-box">
          <div className="head-title">Logo Kampus</div>
          <div className="head-subtitle"></div>
        </div>
      </Container>
      <Container className="full-container logo-kampus-container">
        <div className="item-box card-normal__style">
          <div className="item-box__content">
            <div className="item-box__title">
              <span>Logo Resmi ITBA Al Gazali</span>
            </div>
            <div className="item-box__subtitle text-start">
              <ul>
                <li>
                  Logo Berwarna
                  <span
                    onClick={() =>
                      repositoryData(`about/${logoWarna.id_item}`, "item_file")
                    }
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    {" "}
                    Download
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default LogoKampus;
