import { Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import {
  dateFormat,
  dateFormatDays,
  dateFormatWeekdays,
  dateFormatMonth,
} from "assets/js/dateFormat";
import axios from "axios";
import "./Intro.css";
import { API_URL } from "config";

const Intro = () => {
  const [newsData, setNewsData] = useState([]);
  const [eventData, setEventData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const requestBerita = axios.get(`${API_URL}/berita`);
      const requestEvent = axios.get(`${API_URL}/event`);

      axios
        .all([requestBerita, requestEvent])
        .then(
          axios.spread((...responses) => {
            const responseBerita = responses[0].data
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .slice(0, 6);
            const responseEvent = responses[1].data
              .sort((a, b) => new Date(b.event_date) - new Date(a.event_date))
              .slice(0, 6);

            setNewsData(responseBerita);
            setEventData(responseEvent);
          })
        )
        .catch((errors) => {
          console.error(errors);
        });
    };

    fetchData();
  }, []);

  const handleButton = (id, slug) => {
    window.location.href = `/berita/${id}/${slug}`;
  };

  return (
    <div>
      <Container className="full-container banner-container">
        <div className="banner-box">
          <div className="banner-title">ITBA Al Gazali Barru</div>
          <div className="banner-subtitle">
            Sebagai Kampus yang Damai dan Mencerahkan
          </div>
        </div>
      </Container>
      <Container className="full-container content-container">
        <div className="item-box card-overlap__style">
          <div className="item-box__title text-border ">
            <span>Kabar ITBA Al Gazali</span>
          </div>
          <div className="item-box__subtitle">
            Berita Terkini ITBA Al Gazali Barru
          </div>
          <div className="news-item__container">
            {newsData.map((obj, index) => (
              <div
                className="news-item"
                key={index}
                onClick={() => handleButton(obj.id, obj.news_slug)}
              >
                <div className="news-thumb">
                  <img src={`${API_URL}/news/${obj.news_thumb}`} alt="Thumb" />
                </div>
                <div className="news-title">{obj.news_title}</div>
                <div className="news-date">{dateFormat(obj.createdAt)}</div>
              </div>
            ))}
          </div>
          <div className="button-container">
            <a href="/berita">
              <span>Selengkapnya</span>
            </a>
          </div>
          <div className="item-box__title text-border ">
            <span>Video Profil</span>
          </div>
          <iframe
            className="intro-video"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/v9eXeT0Jn7E?controls=0"
            title="Video Profil Stia AL Gazali Barru"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            autoPlay="1"
          ></iframe>
          <div className="item-box__title text-border ">
            <span>Agenda Kampus</span>
          </div>
          <div className="item-box__subtitle">Pengumuman & Agenda Akademik</div>
          <div className="agenda-item__container">
            {eventData.map((obj, index) => (
              <div className="agenda-item" key={index}>
                <div className="agenda-thumb">
                  <div className="agenda-thumb_box">
                    <span className="agenda-date">
                      {dateFormatDays(obj.event_date)}
                    </span>
                    <span className="agenda-month">
                      {" "}
                      {dateFormatMonth(obj.event_date)}
                    </span>
                    <span className="agenda-day">
                      {" "}
                      {dateFormatWeekdays(obj.event_date)}
                    </span>
                  </div>
                </div>
                <div className="agenda-title">{obj.event_title}</div>
              </div>
            ))}
          </div>
          <div className="button-container">
            <a href="/agenda-akademik">
              <span>Selengkapnya</span>
            </a>
          </div>
        </div>
      </Container>
      <Container className="full-container content-container">
        <div className="item-box beasiswa-box">
          <div className="item-box__title text-border col-black">
            <span>Penerimaan Mahasiswa Baru</span>
          </div>
          <div className="item-box__subtitle">
            Informasi seputar persyaratan, jalur, dan mekanisme pendaftaran
            mahasiswa baru ITBA Al Gazali Barru
          </div>
          <div className="button-container v2">
            <a href="/info-jalur-masuk">
              <span>Selengkapnya</span>
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Intro;
