import { Container } from "react-bootstrap";
import "./Alumni.css";

const Alumni = () => {
  return (
    <div>
      <Container className="full-container head-container">
        <div className="head-box">
          <div className="head-title">Alumni ITBA Al Gazali Barru</div>
          <div className="head-subtitle"></div>
        </div>
      </Container>
      <Container className="full-container penelitian-container">
        <div className="item-box card-normal__style">
          <div className="item-box__content">
            <div className="item-box__title">
              <span>Tracer Study Alumni</span>
            </div>
            <div className="item-box__subtitle">
              Form penginputan data{" "}
              <a
                target={"_blank"}
                href="https://bit.ly/tracer_study_algazalibarru"
                rel="noreferrer"
              >
                <span style={{ color: "blue" }}>Link</span>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Alumni;
