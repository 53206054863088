import { dateFormatAllNumeric } from "./dateFormat";
import axios from "axios";
import { API_URL } from "config";

const Calender = () => {
  const curentDate = document.querySelector(".current-date"),
    daysTag = document.querySelector(".days"),
    prevNextIcon = document.querySelectorAll(".icons svg");

  let date = new Date(),
    currYear = date.getFullYear(),
    currMonth = date.getMonth();

  const months = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const renderCalender = () => {
    let firstDayofMonth = new Date(currYear, currMonth, 1).getDay();
    let lastDateofMonth = new Date(currYear, currMonth + 1, 0).getDate();
    let lastDayofMonth = new Date(
      currYear,
      currMonth,
      lastDateofMonth
    ).getDay();
    let lastDateofLastMonth = new Date(currYear, currMonth, 0).getDate();
    let liTag = "";

    for (let i = firstDayofMonth; i > 0; i--) {
      liTag += `<li dateValue="0" class="inactive">${
        lastDateofLastMonth - i + 1
      }</li>`;
    }

    for (let i = 1; i <= lastDateofMonth; i++) {
      let makeValue = dateFormatAllNumeric(new Date(currYear, currMonth, i));
      let isToday =
        i === date.getDate() &&
        currMonth === new Date().getMonth() &&
        currYear === new Date().getFullYear()
          ? "active"
          : "";
      liTag += `<li dateValue="${makeValue}" class="${isToday}">${i}</li>`;
    }

    for (let i = lastDayofMonth; i < 6; i++) {
      liTag += `<li dateValue="0" class="inactive">${
        i - lastDayofMonth + 1
      }</li>`;
    }
    curentDate.innerText = `${months[currMonth]} ${currYear}`;
    daysTag.innerHTML = liTag;

    let daysButton = document.querySelectorAll(".days li");
    daysButton.forEach((button) => {
      button.addEventListener("click", (obj) => {
        let selectedDate = obj.target.attributes.dateValue.value;
        getDataEvent(selectedDate);
      });
    });
  };

  renderCalender();

  prevNextIcon.forEach((icon) => {
    icon.addEventListener("click", () => {
      currMonth = icon.id === "prev" ? currMonth - 1 : currMonth + 1;

      if (currMonth < 0 || currMonth > 11) {
        date = new Date(currYear, currMonth);
        currYear = date.getFullYear();
        currMonth = date.getMonth();
      } else {
        date = new Date();
      }
      renderCalender();
    });
  });
};
const getDataEvent = (selectedDate) => {
  const connAPIEvent = async () => {
    try {
      const response = await axios.get(`${API_URL}/event`);
      if (response.data) {
        console.log(
          response.data.find((obj) => obj.event_date === selectedDate)
        );
      }
    } catch (err) {
      console.log(err.message);
    } finally {
    }
  };

  connAPIEvent();
};

export default Calender;
