export const dateFormat = (date) => {
  const options = {
    timeZone: "Asia/Jakarta",
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return new Date(date).toLocaleString("id-ID", options);
};

export const dateFormatV2 = (date) => {
  const options = {
    timeZone: "Asia/Jakarta",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return new Date(date).toLocaleString("id-ID", options);
};

export const dateFormatWeekdays = (date) => {
  const options = {
    weekday: "long",
  };

  return new Date(date).toLocaleString("id-ID", options);
};

export const dateFormatDays = (date) => {
  const options = {
    day: "numeric",
  };

  return new Date(date).toLocaleString("id-ID", options).padStart(2, "0");
};

export const dateFormatMonth = (date) => {
  const options = {
    month: "short",
  };

  return new Date(date).toLocaleString("id-ID", options);
};

export const dateFormatGetYear = (date) => {
  const options = {
    year: "numeric",
  };

  return new Date(date).toLocaleString("id-ID", options);
};

export const dateFormatAllNumeric = (date) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const newDate = new Date(date).toLocaleString("id-ID", options);

  const dateParts = newDate.split("/");
  const day = dateParts[0];
  const month = dateParts[1];
  const year = dateParts[2];

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};
