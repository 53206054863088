import { Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";
import "react-quill/dist/quill.snow.css";
import { API_URL } from "config";
import "./Akademik.css";

const About = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/prodi`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <Container className="full-container head-container">
        <div className="head-box">
          <div className="head-title">
            Seputar Bidang Akademik ITBA Al Gazali
          </div>
          <div className="head-subtitle"></div>
        </div>
      </Container>
      <Container className="full-container program-studi-container">
        <div className="item-box box-image card-normal__style">
          <div className="item-box__content">
            <div className="item-box__title">
              <span>Program Studi</span>
            </div>
            <div className="item-box__subtitle text-start">
              {data.map((obj, index) => (
                <ul key={index}>
                  <li>{obj.prodi_name}</li>
                </ul>
              ))}
            </div>
          </div>
        </div>
      </Container>
      <Container className="full-container content-container">
        <div className="item-box beasiswa-box">
          <div className="item-box__title text-border col-black">
            <span>Info Beasiswa</span>
          </div>
          <div className="item-box__subtitle">
            Informasi seputar beasiswa untuk mahasiswa ITBA Al Gazali Barru
          </div>
          <div className="button-container v2">
            <span>Selengkapnya</span>
          </div>
        </div>
      </Container>
      <Container className="full-container">
        <div className="item-box group-box card-normal__style">
          <div className="item-box__child">
            <div className="item-box__title">
              <span>SIAKAD</span>
            </div>
            <div className="item-box__subtitle">Sistem Akademik Mahasiswa</div>
            <div className="button-container">
              <a
                href="https://siakad.algazali.ac.id"
                rel="noopener noreferrer"
                target={"_blank"}
              >
                <span>Selengkapnya</span>
              </a>
            </div>
          </div>
          <div className="item-box__child">
            <div className="item-box__title">
              <span>E-Library</span>
            </div>
            <div className="item-box__subtitle">
              Perpustakaan ITBA Al Gazali
            </div>
            <div className="button-container">
              <a
                href="https://lib.algazali.ac.id"
                rel="noopener noreferrer"
                target={"_blank"}
              >
                <span>Selengkapnya</span>
              </a>
            </div>
          </div>
          <div className="item-box__child">
            <div className="item-box__title">
              <span>SISTER</span>
            </div>
            <div className="item-box__subtitle">
              Sistem Informasi Sumberdaya Terintegrasi
            </div>
            <div className="button-container">
              <a
                href="http://sister.algazali.ac.id./"
                rel="noopener noreferrer"
                target={"_blank"}
              >
                <span>Selengkapnya</span>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default About;
