import { Container } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import repositoryData from "assets/js/DownloadRepository";
import axios from "axios";
import "./About.css";
import { API_URL } from "config";

const About = () => {
  const [sejarah, setSejarah] = useState([]);
  const [visimisi, setVisiMisi] = useState([]);
  const [struktur, setStruktur] = useState([]);
  const [statuta, setStatuta] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const requestSejarah = axios.get(`${API_URL}/about/sejarah_kampus`);
      const requestVisiMisi = axios.get(`${API_URL}/about/visi_misi`);
      const requestStruktur = axios.get(`${API_URL}/about/struktur_organisasi`);
      const requestStatuta = axios.get(
        `${API_URL}/about/statuta_perguruan_tinggi`
      );

      axios
        .all([requestSejarah, requestVisiMisi, requestStruktur, requestStatuta])
        .then(
          axios.spread((...responses) => {
            const responseSejarah = responses[0];
            const responseVisiMisi = responses[1];
            const responseStruktur = responses[2];
            const responseStatuta = responses[3];

            setSejarah(responseSejarah.data);
            setVisiMisi(responseVisiMisi.data);
            setStruktur(responseStruktur.data);
            setStatuta(responseStatuta.data);
          })
        )
        .catch((errors) => {
          console.error(errors);
        });
    };

    fetchData();
  }, []);

  return (
    <div>
      <Container className="full-container head-container">
        <div className="head-box">
          <div className="head-title">Tentang ITBA Al Gazali</div>
          <div className="head-subtitle">
            Mengenal lebih dekat kampus ITBA Al Gazali yang damai dan
            mencerahkan
          </div>
        </div>
      </Container>
      <Container className="full-container sejarah-container">
        <div className="item-box box-image card-normal__style">
          <div className="item-box__thumb">
            <img src={require("../assets/img/itba.png")} alt="Thumb" />
          </div>
          <div className="item-box__content">
            <div className="item-box__title">
              <span>Sejarah</span>
            </div>
            <div className="item-box__subtitle">{sejarah.item_preview}</div>
            <div className="button-container">
              <a href="/sejarah-singkat">
                <span>Selengkapnya</span>
              </a>
            </div>
          </div>
        </div>
      </Container>
      <Container className="full-container content-container">
        <div className="item-box visimisi-box">
          <div className="item-box__title visimisi-box_title">
            <span>Visi Misi</span>
          </div>
          <div className="item-box__subtitle">
            <div
              className="ql-editor"
              dangerouslySetInnerHTML={{
                __html: visimisi.item_content,
              }}
            ></div>
          </div>
        </div>
      </Container>
      <Container className="full-container struktur-org-container">
        <div className="item-box box-image card-normal__style">
          <div className="item-box__thumb">
            <img src={require("../assets/img/itba.png")} alt="Thumb" />
          </div>
          <div className="item-box__content">
            <div className="item-box__title">
              <span>Struktur Organisasi</span>
            </div>
            <div className="item-box__subtitle">{struktur.item_preview}</div>
            <div className="button-container">
              <a href="/struktur-organisasi">
                <span>Selengkapnya</span>
              </a>
            </div>
          </div>
        </div>
      </Container>
      <Container className="full-container">
        <div className="item-box group-box card-normal__style">
          <div className="item-box__child">
            <div className="item-box__title">
              <span>Statuta Perguruan Tinggi</span>
            </div>
            <div className="item-box__subtitle">
              Dokumen statuta perguruan tinggi
            </div>
            <div className="button-container">
              <button
                button-data="statuta_perguruan_tinggi"
                onClick={() =>
                  repositoryData(`about/${statuta.id_item}`, "item_file")
                }
              >
                <span>Download</span>
              </button>
            </div>
          </div>
          <div className="item-box__child">
            <div className="item-box__title">
              <span>Legalitas Kampus</span>
            </div>
            <div className="item-box__subtitle">
              Dokumen akreditasi kampus & Program studi
            </div>
            <div className="button-container">
              <a href="/legalitas-kampus">
                <span>Selengkapnya</span>
              </a>
            </div>
          </div>
          <div className="item-box__child">
            <div className="item-box__title">
              <span>Logo Resmi Kampus</span>
            </div>
            <div className="item-box__subtitle">Logo resmi kampus</div>
            <div className="button-container">
              <a href="/logo-kampus">
                <span>Selengkapnya</span>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default About;
