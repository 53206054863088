import { Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import "./StrukturOrganisasi.css";
import axios from "axios";
import { API_URL } from "config";

const StrukturOrganisasi = () => {
  const [struktur, setStruktur] = useState([]);
  const [pimpinan, setPimpinan] = useState([]);
  const [senat, setSenat] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const requestStruktur = axios.get(`${API_URL}/about/struktur_organisasi`);
      const requestPimpinan = axios.get(
        `${API_URL}/about/pimpinan_perguruan_tinggi`
      );
      const requestSenat = axios.get(`${API_URL}/about/senat_akademik`);

      axios
        .all([requestStruktur, requestPimpinan, requestSenat])
        .then(
          axios.spread((...responses) => {
            const responseStruktur = responses[0];
            const responsePimpinan = responses[1];
            const responseSenat = responses[2];

            setStruktur(responseStruktur.data);
            setPimpinan(responsePimpinan.data);
            setSenat(responseSenat.data);
          })
        )
        .catch((errors) => {
          console.error(errors);
        });
    };

    fetchData();
  }, []);

  return (
    <div>
      <Container className="full-container head-container">
        <div className="head-box">
          <div className="head-title">Struktur ITBA Al Gazali</div>
          <div className="head-subtitle"></div>
        </div>
      </Container>
      <Container className="full-container sejarah-singkat-container">
        <div className="item-box card-normal__style">
          <div className="item-box__content">
            <div className="item-box__title">
              <span>Struktur Organisasi</span>
            </div>
            <div className="item-box__subtitle">Bagan Struktur Organisasi</div>
            <div className="struktur_thumb">
              <img
                src={`${API_URL}/repository/${struktur.item_file}`}
                alt="Thumb"
              />
            </div>
          </div>
        </div>
      </Container>
      <Container className="full-container struktur-org-container">
        <div className="item-box box-image card-normal__style">
          <div className="item-box__thumb">
            <img
              id="pimpinan-struktur_organisasi"
              src={process.env.PUBLIC_URL + "uploads/img/pimpinan.png"}
              alt="Thumb"
            />
          </div>
          <div className="item-box__content">
            <div className="item-box__title">
              <span>Pimpinan Perguruan Tinggi</span>
            </div>
            <div className="item-box__subtitle">{pimpinan.item_preview}</div>
            <div className="button-container">
              <a href="/pimpinan-perguruan-tinggi">
                <span>Selengkapnya</span>
              </a>
            </div>
          </div>
        </div>
      </Container>
      <Container className="full-container sejarah-container">
        <div className="item-box box-image card-normal__style">
          <div className="item-box__thumb">
            <img
              src={process.env.PUBLIC_URL + "uploads/img/logo-3.png"}
              alt="Thumb"
            />
          </div>
          <div className="item-box__content">
            <div className="item-box__title">
              <span>Senat Akademik</span>
            </div>
            <div className="item-box__subtitle">{senat.item_preview}</div>
            <div className="button-container">
              <a href="/senat-akademik">
                <span>Selengkapnya</span>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default StrukturOrganisasi;
